
import Order from '@/models/Order'
import ViewModel from '@/models/ViewModel'
import { Component, Ref, Vue } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import PageHeader from '@/components/PageHeader/PageHeader.vue'
import WebMessage from '@/models/WebMessage'
import AuditHistory from '@/components/AuditHistory/AuditHistoryTable.vue'
import SyncModal from '@/components/MediaPlanItemSyncModal/SyncModal.vue'
import LineItem from '@/models/LineItem'
import ModularReport from '@/components/ModularReport/ModularReport.vue'
import RemoteTargetTable from '@/components/RemoteTargetTable/RemoteTargetTable.vue'
import LineItemsTable from '../LineItem/components/LineItemsTable.vue'
import OrderForm from './components/OrderForm.vue'
import CreativesTable from './components/CreativesTable.vue'

@Component({
  components: {
    Widget,
    IconAction,
    FormInput,
    CompanyPicker,
    SelectPicker,
    LineItemsTable,
    PageHeader,
    OrderForm,
    AuditHistory,
    CreativesTable,
    SyncModal,
    ModularReport,
    RemoteTargetTable,
  },
})
export default class OrderView extends ViewModel {
  @Ref() readonly audit_history!: any

  @Ref() form_submit!: any

  @Ref() orderForm!: any

  public order: Order = new Order()

  public fav_order: boolean = false

  public show_settings: boolean = false

  public syncItem: any = null

  public modal: any = {
    delete: false,
    history: false,
  }

  public get reportSettings() {
    if (this.order.id && this.order.id.length) {
      return {
        adserver_orders: [this.order.id],
      }
    }

    return {}
  }

  public mounted() {
    setTimeout(() => {
      this.getOrder()
    }, 300)
  }

  public getOrder() {
    Order.find(this.$route.params.id).then((response: any) => {
      // console.log('the order', response)
      this.order = response
    })
  }

  public toggleBodyScrollbar(visible: any) {
    const body = document.getElementsByTagName('body')[0]

    if (visible) body.classList.add('overflow-hidden')
    else body.classList.remove('overflow-hidden')
  }

  public viewHistory(order: Order) {
    this.modal.history = true

    // this.audit_history.viewHistory(order)

    setTimeout(() => {
      Vue.set(this.audit_history, 'history_loading', true)

      this.audit_history.setModel(order)

      setTimeout(() => {
        Vue.set(this.audit_history, 'ready', true)

        Vue.set(this.audit_history, 'history_loading', false)
      }, 200)
    }, 300)
  }

  public cancel() {
    this.$router.push({ name: 'orders' })
  }

  public syncLineItemData(line_item: LineItem) {
    this.syncItem = line_item
  }
}
